var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-run"},[_c('div',{staticClass:"modal-shade"}),_c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
                translateY: ['-100px', 0],
                opacity: {
                    value: ['0.0', '1.0'],
                    duration: 5000,
                },
                delay: 500,
                duration: 750,
                elasticity: 100,
            }),expression:"{\n                translateY: ['-100px', 0],\n                opacity: {\n                    value: ['0.0', '1.0'],\n                    duration: 5000,\n                },\n                delay: 500,\n                duration: 750,\n                elasticity: 100,\n            }"}],staticClass:"modal-inner"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"page",attrs:{"active":_vm.page == 0,"id":0}},[_c('div',{staticClass:"content content-in",attrs:{"active":_vm.page == 0}},[_c('h1',[_vm._v("Welcome to Divinitor Minerva")]),_c('p',[_vm._v(" Minerva is a reference website for Project Duck. ")]),_vm._m(0),_c('p',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v("Legal/Regulatory Information is provided here")]),_vm._v(". By continuing, you accept these terms. ")]),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                    loop: true,
                                    duration: 3000,
                                    delay: 750,
                                    easing: 'easeInOutExpo'
                                }),expression:"{\n                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\n                                    loop: true,\n                                    duration: 3000,\n                                    delay: 750,\n                                    easing: 'easeInOutExpo'\n                                }"}],staticClass:"dv-button",on:{"click":function($event){return _vm.finish()}}},[_vm._v(" Let's get started ")])])])]),_c('div',{staticClass:"page",attrs:{"active":_vm.page == 1,"id":1}},[_c('div',{staticClass:"content content-in"},[_c('h4',[_vm._v("Quasi-legal stuff")]),_vm._m(1),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                    loop: true,
                                    duration: 3000,
                                    delay: 750,
                                    easing: 'easeInOutExpo'
                                }),expression:"{\n                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\n                                    loop: true,\n                                    duration: 3000,\n                                    delay: 750,\n                                    easing: 'easeInOutExpo'\n                                }"}],on:{"click":function($event){return _vm.finish()}}},[_vm._v(" Sounds Good ")])])])]),_c('div',{staticClass:"page-indicator"},[_c('div',{staticClass:"indicator",attrs:{"active":_vm.page == 0,"disabled":_vm.loginStatus == 1 || _vm.loginStatus == 2},on:{"click":function($event){return _vm.changePage(0)}}})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Please note that this site is in limited beta. There will be bugs, incomplete features, and duct tape everywhere. If you encounter an issue, please let "),_c('strong',[_vm._v("Vahr")]),_vm._v(" know. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"legal"},[_vm._v(" This site does not use cookies for any of its functionality. We do use "),_c('a',{attrs:{"href":"https://docs.microsoft.com/en-us/azure/application-insights/app-insights-overview","target":"_blank"}},[_vm._v("Azure Application Insights")]),_vm._v(" to gather basic usage and performance information to monitor site performance, issues, and usage. You can opt out of this data collection by blocking cookies or using a tracker blocker. If you log in to Minerva with Discord, your Discord information is only used to verify eligibility to access limited features and prevent fraudulent use. You may opt out of this by not signing in with Discord. A small amount of your browser's "),_c('a',{attrs:{"href":"https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage","target":"_blank"}},[_vm._v("local storage")]),_vm._v(" is used to store your login token, login name, preferences, and a few bits of information (like if you have seen this message you're reading right now or not) necessary to the proper operation of the site. You may clear this data at any time by using your browser's local storage options. Vahr does not guarantee or have any obligation to the availability of this site and its services. These terms may change at any time without prior notice, however if they do change you will be notified the next time you access this site. ")])}]

export { render, staticRenderFns }